import { Member } from "../types";

export const validateMember = (member: Member): string[] => {
    const errors: string[] = [];
  
    if (!member.member) {
      errors.push("Der Mitgliedsantrag muss abgeschlossen worden sein");
    }
  
    if (!member.memberNumber || member.memberNumber.trim() === "") {
      errors.push("Die Mitgliedsnummer darf nicht leer sein.");
    }
  
    if (!member.address_city || member.address_city.trim() === "") {
      errors.push("Der Wohnort darf nicht leer sein.");
    }
  
    if (!member.address_postalcode || member.address_postalcode.trim() === "") {
      errors.push("Die Postleitzahl darf nicht leer sein.");
    }
  
    if (!member.address_street || member.address_street.trim() === "") {
      errors.push("Die Straße darf nicht leer sein.");
    }
  
    if (!member.primaryEmail || member.primaryEmail.trim() === "") {
      errors.push("Die E-Mail-Adresse darf nicht leer sein.");
    }
  
    if (!member.firstname || member.firstname.trim() === "") {
      errors.push("Der Vorname darf nicht leer sein.");
    }
  
    if (!member.salutation || member.salutation.trim() === "") {
      errors.push("Die Anrede darf nicht leer sein.");
    }
  
    if (!member.lastname || member.lastname.trim() === "") {
      errors.push("Der Nachname darf nicht leer sein.");
    }
  
    if (!member.birthdate || member.birthdate.trim() === "") {
      errors.push("Das Geburtsdatum darf nicht leer sein.");
    } else if (isNaN(Date.parse(member.birthdate))) {
      errors.push("Das Geburtsdatum muss ein gültiges Datum sein.");
    }
  
    if (!member.birthplace || member.birthplace.trim() === "") {
      errors.push("Der Geburtsort darf nicht leer sein. Diesen benötigen wir zur Generierung der Steuerbescheinigungen!");
    }
  
    if (!member.taxNumber || member.taxNumber.trim() === "") {
      errors.push("Ihre Steuer-ID darf nicht leer sein. Diese benötigen wir zur Generierung der Steuerbescheinigungen!");
    }
  
    return errors;
  };