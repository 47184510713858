import { Alert } from "@mui/material";

export const displayValidationErrors = (errors: string[]): JSX.Element => {
    return (
        <>
            {errors.map((error, index) => (
                <Alert severity="error" key={index} style={{ marginBottom: '10px' }}>
                    {error}
                </Alert>
            ))}
        </>
    );
};