import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"

import { useParams } from 'react-router-dom';
import { useState } from "react";

interface ServiceUnavailableErrorProps {
    isMobile: boolean
}


export const ServiceUnavailableError: React.FC<ServiceUnavailableErrorProps> = (props) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const { path } = useParams();
    const [safariWarning, setSafariWarningOpen] = useState(isSafari);
    return (
        <div style={{
            padding: props.isMobile ? 10 : 20,
            margin: props.isMobile ? "10px 8px" : "20px 25%",
            marginBottom: props.isMobile ? 80 : 20,
            width: props.isMobile ? "calc(100% - 16px - 20px)" : "calc(50% - 40px)"
        }}>
            <Dialog
                open={safariWarning}
                onClose={() => setSafariWarningOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Safari Browser nicht unterstützt!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Achtung! Es wurde erkannt, dass Sie den Safari Browser verwenden. Dieser wird nicht vollständig unterstützt und es kann zu Problemen in der Anwendung führen!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSafariWarningOpen(false)} autoFocus>
                        Verstanden
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography variant="h4" sx={{ marginBottom: "5px", display: "inline-block" }}>Mitgliederportal</Typography>
            <Typography variant="body1">Leider ist unser Server zur Zeit nicht erreichbar. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
                Sollte der Fehler auch nach längerer Zeit nicht behoben sein, kontaktieren Sie uns bitte.</Typography>
            <Typography variant="body2">Fehler 503</Typography>
            <Button variant="contained" disableElevation sx={{ marginTop: "10px" }} onClick={async () => {
                window.location.pathname = decodeURIComponent(path as string);
            }}>Erneut versuchen</Button>
        </div>
    )
}
