import { Bank_Account, Loan, Member, TaskDto } from '../types';
import { Box, Button, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import AuthService from '../services/AuthService';
import DataService from '../services/DataService';
import EmptyTypesService from '../services/EmptyTypesService';
import { Loading } from '../components/Loading';
import MemberService from '../services/MemberService';
import { ProjectsIframe } from './iframes/ProjectsIframe';
import TaskService from '../services/TaskService';
import { displayValidationErrors } from '../components/ValidationErrors';
import styles from '../styles/Table.module.css';
import { useNavigate } from 'react-router-dom';
import { validateMember } from '../validation/memberValidationService';

interface LoanProps {
    isMobile: boolean;
    config: Config;
}

export const ProjectLoans: React.FC<LoanProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{ loan: boolean }>({ loan: false });
    const [tasks, setTasks] = useState<TaskDto[]>([]);
    const [memberData, setMemberData] = useState<Member>(EmptyTypesService.emptyMember());
    const [bankData, setBankData] = useState<Bank_Account[]>([]);
    const [memberDataValidation, setMemberDataValidation] = useState<string[]>(['Prüfe Mitgliedsdaten'])


    const [loanData, setLoanData] = useState<Loan[]>([{
        id: 'Nicht vorhanden',
        name: 'Nicht vorhanden',
        value: 0.00,
        signedDate: 'Nicht vorhanden',
        project: { name: 'Nicht vorhanden' },
        payedDate: 'Nicht vorhanden',
        state: 'Unbekannt',
        lastModifiedDate: new Date(),
    }]);

    useEffect(() => {
        const loadTasks = async () => {
            const taskList = await TaskService.getTaskList();
            setTasks(taskList);
        };

        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            loadTasks();
            DataService.performRequest(`/rest/services/beg_PortalLoanService/getLoans${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setLoanData, 'loan', navigate);
            DataService.performRequest(`/member/info${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setMemberData, 'member', navigate);
            DataService.performRequest(`/bankaccount${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setBankData, 'bank', navigate);
        }
    }, []);

    useEffect(() => {
        setMemberDataValidation(validateMember(memberData));
    }, [memberData])


    if (!finishedLoading.loan) {
        return <Loading />;
    }

    const extractTaskForLoan = (loanId: string): TaskDto | null => {
        return tasks.find(task => task.variables['loanId'] === loanId) ?? null;
    };

    const to2dArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.sort((a: Loan, b: Loan) => new Date(a.lastModifiedDate).getTime() - new Date(b.lastModifiedDate).getTime());
        objectArray.forEach((objectEl: Loan) => {
            returnElement.push([
                objectEl.name,
                getValueCell(objectEl),
                objectEl.project.name,
                getLoanState(objectEl.state),
                objectEl.signedDate ? new Date(objectEl.signedDate).toLocaleDateString() : '',
                objectEl.payedDate ? new Date(objectEl.payedDate).toLocaleDateString() : '',
                extractTaskForLoan(objectEl.id) ? renderTaskButton(extractTaskForLoan(objectEl.id)) : 'Keine Aufgabe',
            ]);

        });
        return returnElement;
    };

    const getValueCell = (objectEl: Loan) => {
        if (objectEl.value) {
            return objectEl.value.toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' €';
        } else {
            return <>BEANTRAGT {objectEl?.desiredValue} € {objectEl.state === 'INTENT' && <>(<Link onClick={() => navigate(`/projectloans/edit/${objectEl.id}`)}>Ändern</Link>)</>}</>;
        }
    };

    const getTaskText = (key: string) => {
        switch (key) {
            case 'sign_loan_contract':
                return 'Darlehensvertrag abschließen';
            case 'check_changes':
                return 'Änderungen überprüfen';
            default:
                return key;
        }
    };

    const renderTaskButton = (taskDto: TaskDto | null) => {
        if (taskDto == null) return 'Keine Aufgabe';
        return (<Button variant='contained' size='small' onClick={() => {
            navigate(`/tasks/task/${taskDto.id}`);
        }
        }>{getTaskText(taskDto.taskDefinitionKey)}</Button>);
    };


    const getLoanState = (state: string) => {
        //ist im System schon rechtschreibfehler, hab den nur übernommen (canceled)
        if (state === 'CANCELED') return 'Storniert';
        else if (state === 'CLOSED') return 'Geschlossen';
        else if (state === 'DRAFT') return 'Entwurf';
        else if (state === 'INTENT') return 'Absichtserklärung';
        else if (state === 'PAYED') return 'Bezahlt';
        else if (state === 'SENT') return 'Vertrag versendet';
        else if (state === 'SIGNED') return 'Unterschrieben';
        else if (state === 'CHANGES_REQUESTED') return 'Änderungen angefragt';
        else if (state === 'APPROVED') return 'Freigegeben';
        else return 'Unbekannt';
    };
    const headerRows = ['Bezeichnung', 'Wert', 'Projektname', 'Darlehenstatus', 'Unterschrieben am', 'Ein-/Auszahlung am', 'Aufgabe'];
    let colorChange = false;
    return (
        <Box
            className='content'
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            padding={2}
        ><Box
            className='tableDiv'
            width={props.isMobile ? '100%' : '1024px'}
            display='inline-block'
        >
                {memberDataValidation.length > 0 &&
                    (<>
                        <Typography variant="h6">Profil noch nicht vollständig!</Typography>
                        <Typography>Bevor Sie ein Darlehen abschließen können müssen noch folgende Daten in <Link onClick={() => navigate('/personaldata/edit')}>Ihrem Profil</Link> hinterlegt werden:</Typography>
                        {displayValidationErrors(memberDataValidation)}
                    </>)}

                <Typography variant='h6'>Projektdarlehen</Typography>

                {
                    <div style={props.isMobile ? { overflow: 'scroll' } : {}}>
                        <table className={styles.titletable}>
                            <tbody>
                                <tr>
                                    {headerRows.map((headerRow: any) => <th key={Math.random()} className={styles.titletableTitle}>{headerRow}</th>)}
                                </tr>
                                {to2dArray(loanData).map((row: any) => {
                                    colorChange = !colorChange;
                                    return <tr key={Math.random()}>
                                        {row.map((cell: any) =>
                                            <td key={Math.random()} className={styles.titletableCell} style={{ backgroundColor: colorChange ? '#F8F8F8' : '#eaf4e5' }}>{cell}</td>,
                                        )}
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                }
                <div style={{ marginTop: 15 }}>
                    <Typography variant='h6'>Aktuelle Projekte</Typography>
                    <ProjectsIframe isMobile={props.isMobile} config={props.config} inPortal={true} allowParticipation={memberDataValidation.length === 0} />
                </div>
            </Box>
        </Box>
    );

};