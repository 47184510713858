import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Bank_Account, Member } from '../types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AuthService from '../services/AuthService';
import DataService from '../services/DataService';
import EmptyTypesService from '../services/EmptyTypesService';
import EnumToStringService from '../services/EnumToStringService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputValue from '../classes/InputValue';
import { Loading } from '../components/Loading';
import { LoadingButton } from '@mui/lab';
import MemberService from '../services/MemberService';
import { validationService } from '../validation/ValidationService';

interface NewLoanProps {
    isMobile: boolean;
    config: Config;
}

export const NewLoan: React.FC<NewLoanProps> = (props) => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [finishedLoading, setFinishedLoading] = useState<{
        nLoanMember: boolean,
        nLoanProject: boolean,
        nLoanBankAccounts: boolean
    }>({ nLoanMember: false, nLoanProject: false, nLoanBankAccounts: false });
    const [memberData, setMemberData] = useState<Member>(EmptyTypesService.emptyMember());
    const [projectData, setProjectData] = useState<Project[]>([EmptyTypesService.emptyProject()]);
    //Saves list of payment options from request 
    const [bankData, setBankData] = useState<Bank_Account[]>([]);

    //Var if button should show "Loading"
    const [btnIsLoading, setBtnIsLoading] = useState<boolean>(false);
    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false);
    const [isPreview, setPreview] = useState<boolean>(false);

    const isOrganisation = () => {
        return memberData.type !== 'beg_Contacts';
    };
    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/member/info${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setMemberData, 'nLoanMember', navigate);
            DataService.performRequest(`/projects?id=${id}`, finishedLoading, setFinishedLoading, setProjectData, 'nLoanProject', navigate);
            updateBankAccounts();
        }
    }, []);
    const updateBankAccounts = () => {
        DataService.performRequest(`/bankaccount${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setBankData, 'nLoanBankAccounts', navigate)
            .then((response) => {
                //@ts-ignore
                const rawObject: Bank_Account[] = response.data;
                let defaultBA: Bank_Account = EmptyTypesService.emptyBankAccount();
                let loanCollectBA: Bank_Account = EmptyTypesService.emptyBankAccount();
                rawObject.map((bankAccount: Bank_Account) => {
                    if (bankAccount.bankAccountTypes.includes('LOAN_COLLECT')) {
                        loanCollectBA = bankAccount;
                    }
                    if (bankAccount.bankAccountTypes.includes('DEFAULT')) {
                        defaultBA = bankAccount;
                    }
                });
                if (loanCollectBA.id === '' && defaultBA.id !== '') {
                    values.bankAccountId.setValue(defaultBA.id);
                    values.bankAccountId.setInfo('SET');
                } else if (loanCollectBA.id !== '') {
                    values.bankAccountId.setValue(loanCollectBA.id);
                    values.bankAccountId.setInfo('SET');
                }
            });
    };

    const [values] = useState<any>({
        loanSum: new InputValue(props.config.loanMinSum, false),
        paymentType: new InputValue('seperate', false),
        bankAccountId: new InputValue('', false),
    });
    const style = {
        marginTop: '10px',
    };

    const sendRequest = async () => {
        let reqValues: any = {
            loanSum: values.loanSum.getValue(),
            paymentType: getPaymentTypeFromOption(values.paymentType.getValue()),
            project: { id: projectData[0].id },
        };
        if (values.bankAccountId.getValue() !== '' && values.bankAccountId.getInfo() === 'SELECTED') {
            reqValues = { ...reqValues, bankAccount: { id: values.bankAccountId.getValue() } };
        }
        await DataService.performAsyncPostRequest(`/loan/application`, navigate, memberData.type === 'beg_Contacts' ?
            { contact: { id: memberData.id }, ...reqValues } : { account: { id: memberData.id }, ...reqValues });
    };

    const changeBankAccountTypesRequest = (bankAccount: Bank_Account) => {
        setBtnIsLoading(true);
        let bacctypes = bankAccount.bankAccountTypes;
        if (!bacctypes.includes('LOAN_COLLECT')) bacctypes.push('LOAN_COLLECT');
        if (!bacctypes.includes('LOAN_INTEREST')) bacctypes.push('LOAN_INTEREST');
        if (!bacctypes.includes('LOAN_REPAYMENTS')) bacctypes.push('LOAN_REPAYMENTS');
        DataService.performAsyncPutRequest(`/bankaccount/bankaccounttype`, navigate,
            {
                bankAcountId: bankAccount.id,
                bankAccountTypes: bacctypes.filter((s) => !EnumToStringService.getBankAccountType(s).startsWith('Darlehen ')),
            },
        ).then(() => {
            updateBankAccounts();
            setBtnIsLoading(false);
        });
    };

    //temporary function until different approches to paymentoption are unified
    const getPaymentTypeFromOption = (value: string) => {
        if (value === 'sepa') return 'DEBT_ORDER';
        else if (value === 'seperate') return 'BANK_TRANSFER';
        else return value;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueBefore = values['loanSum'].getValue();
        values['loanSum'].setValue(e.target.value);
        const newValue = parseInt(e.target.value);
        if (!validationService.validateFunction(values, 'loanSum', 'positiveFloat', false, false, props.config)) {
            values['loanSum'].setValue(valueBefore);
        }
        if (newValue % 1000 !== 0) {
            values['loanSum'].setError('Darlehensbetrag muss in 1000er Schritten angegeben werden!');
        }

        // Assuming that totalDesiredValue and maxSignedLoansSum are defined
        if (newValue > (projectData[0].maxSignedLoansSum - projectData[0].desiredLoanSum)) {
            values['loanSum'].setError('Der Gesamtbetrag der gewünschten Darlehen übersteigt die maximal zulässige Summe!');
        }

        reload(!reloadVar);
    };

    if (!finishedLoading.nLoanMember || !finishedLoading.nLoanProject || !finishedLoading.nLoanBankAccounts) {
        return <Loading />;
    }

    return (
        <Box display='flex' justifyContent='center'>
            <div className='tableDiv' style={{ width: props.isMobile ? '100%' : '50%', display: 'inline-block' }}>
                <Typography variant='h6'>Zeichnungsabsicht</Typography>
                <Typography variant='body2'>Wir freuen uns, dass Sie eine Zeichnungsabsicht für das
                    Projekt {projectData[0].name} abgeben möchten.
                    Wir freuen uns, dass Sie eine Zeichnungsabsicht für das Projekt {projectData[0].name} abgeben
                    möchten. Die Zeichnungsabsicht stellt eine vorläufige Interessenbekundung dar und muss noch von der
                    Vorstandschaft bestätigt werden. Sobald dies erfolgt, wird ein Darlehensvertrag erstellt, den Sie
                    anschließend hier im Mitgliederportal abschließen können.
                    Bitte überprüfen Sie daher Ihre persönlichen Daten, da diese später im Darlehensvertrag verwendet
                    werden. Sollten Ihre Daten nicht korrekt sein, können Sie sie <Link
                        href='/personaldata/edit'>hier</Link> anpassen.
                </Typography>

                {!isPreview && <div><Accordion sx={{ mt: 2 }} defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1-content'
                        id='panel1-header'
                    >
                        {!isOrganisation() ? 'Persönliche Angaben' : 'Kontaktdaten'}
                    </AccordionSummary>
                    <AccordionDetails>
                        {!isOrganisation() ? <><FormControl style={style} fullWidth>
                            <InputLabel size='small'>Anrede *</InputLabel>
                            <Select
                                value={memberData.salutation}
                                label='Anrede'
                                name='salutation'
                                variant='outlined'
                                size='small'
                                disabled
                            >
                                <MenuItem value={'Herr'}>Herr</MenuItem>
                                <MenuItem value={'Frau'}>Frau</MenuItem>
                                <MenuItem value={'Keine Anrede'}>Keine Anrede</MenuItem>
                            </Select>
                        </FormControl>
                            {memberData.title && (
                                <TextField
                                    style={style}
                                    fullWidth
                                    value={memberData.title}
                                    name='title'
                                    label='Titel'
                                    variant='outlined'
                                    size='small'
                                    disabled
                                />
                            )}
                            <TextField style={style} fullWidth value={memberData.firstname} name='firstname'
                                label='Vorname' variant='outlined' size='small' required disabled />
                            <TextField style={style} fullWidth value={memberData.lastname} name='lastname'
                                label='Nachname' variant='outlined' size='small' required disabled />
                        </> : <>
                            <TextField style={style} fullWidth value={memberData.name} name='name' label='Name'
                                variant='outlined' size='small' required disabled />
                        </>}

                        <TextField style={style} fullWidth value={memberData.address_street} name='addressStreet'
                            label='Straße, Hausnummer' variant='outlined' size='small' required disabled />
                        {memberData.address_extra && (
                            <TextField
                                style={style}
                                fullWidth
                                value={memberData.address_extra}
                                name='addressExtra'
                                label='Addresszusatz (optional)'
                                variant='outlined'
                                size='small'
                                disabled
                            />
                        )}
                        <TextField style={style} fullWidth value={memberData.address_postalcode}
                            name='addressPostalcode'
                            label='Postleitzahl' variant='outlined' size='small' required disabled />
                        <TextField style={style} fullWidth value={memberData.address_city} name='addressCity'
                            label='Ort'
                            variant='outlined' size='small' required disabled />
                        <TextField style={style} fullWidth value={memberData.primaryEmail} name='email' type='email'
                            label='E-Mail' variant='outlined' size='small' required disabled />
                    </AccordionDetails>
                </Accordion>

                    <Typography variant='subtitle1' style={style}>Darlehen</Typography>
                    <Typography>Es können noch {(projectData[0].maxSignedLoansSum - projectData[0].desiredLoanSum).toLocaleString('de-DE')} € an Darlehen gegeben werden!</Typography>
                    <TextField type='number' disabled={isPreview} fullWidth style={style}
                        value={values.loanSum.getValue()}
                        onChange={handleChange} error={values.loanSum.getError()} name='loanSum'
                        label='Betrag des Darlehens' variant='outlined' size='small' required InputProps={{
                            inputProps: {
                                min: props.config.loanMinSum,
                                step: 1000,
                            },
                        }} />
                    {values.loanSum.getError() ? (<FormHelperText error={true}>
                        {values.loanSum.getError()}
                    </FormHelperText>) : ''}
                    <FormControl style={style} fullWidth>
                        <Typography variant='subtitle1' style={style}>Zahlungsoption</Typography>
                        <Typography variant='body2'>
                            Für dieses Projekt kann nur die Zahlungsoption Banküberweisung verwendet werden.
                        </Typography>
                        <RadioGroup
                            value={values.paymentType.getValue()}
                            onChange={e => {
                                values.paymentType.setValue(e.target.value);
                                reload(!reloadVar);
                            }}
                            name='paymentOption'
                        >
                            <FormControlLabel key='seperate' value='seperate' control={<Radio />}
                                label='Banküberweisung' disabled={isPreview} />
                        </RadioGroup>
                    </FormControl>
                </div>}

                {isPreview &&
                    <Alert severity='warning' sx={{ my: 2 }}>Bitte überprüfen Sie Ihre Angaben auf Richtigkeit!</Alert>}

                {isPreview && <div>
                    <Typography variant='subtitle1'
                        sx={{ mt: 2 }}>{!isOrganisation() ? 'Persönliche Angaben' : 'Kontaktdaten'}</Typography>
                    {!isOrganisation() ? (
                        <>
                            <Typography><strong>Anrede:</strong> {memberData.salutation}</Typography>
                            {memberData.title && <Typography><strong>Titel:</strong> {memberData.title}</Typography>}
                            <Typography><strong>Vorname:</strong> {memberData.firstname}</Typography>
                            <Typography><strong>Nachname:</strong> {memberData.lastname}</Typography>
                        </>
                    ) : (
                        <Typography><strong>Name:</strong> {memberData.name}</Typography>
                    )}
                    <Typography><strong>Straße, Hausnummer:</strong> {memberData.address_street}</Typography>
                    {memberData.address_extra &&
                        <Typography><strong>Addresszusatz:</strong> {memberData.address_extra}</Typography>}
                    <Typography><strong>Postleitzahl:</strong> {memberData.address_postalcode}</Typography>
                    <Typography><strong>Ort:</strong> {memberData.address_city}</Typography>
                    <Typography><strong>E-Mail:</strong> {memberData.primaryEmail}</Typography>

                    <Typography variant='subtitle1' sx={{ mt: 2 }}>Darlehen</Typography>
                    <Typography><strong>Betrag des Darlehens:</strong> {values.loanSum.getValue()} €</Typography>

                    <Typography variant='subtitle1' sx={{ mt: 2 }}>Zahlungsoption</Typography>
                    <Typography><strong>Zahlungsoption:</strong> Banküberweisung</Typography>
                </div>}

                {isPreview &&
                    <Alert severity='info' sx={{ my: 2 }}>Der tatsächliche Vertragsabschluss erfolgt erst nach Freigabe
                        durch die Vorstandschaft und einer erneuten Zustimmung Ihrerseits.</Alert>}

                {!isPreview && <div style={{ textAlign: 'right' }}>
                    <Button
                        variant='contained'
                        onClick={() => setPreview(true)}
                    >
                        Weiter
                    </Button>
                </div>
                }

                {isPreview && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                        variant='contained'
                        onClick={() => setPreview(false)}
                        sx={{ backgroundColor: 'grey', color: 'white' }}
                    >
                        Zurück
                    </Button>

                    <LoadingButton
                        loading={btnIsLoading}
                        variant='contained'
                        disableElevation
                        onClick={async () => {
                            setBtnIsLoading(true);
                            let isError = false;
                            if (!validationService.validateFunction(values, 'loanSum', 'positiveFloat', false, false, props.config)) {
                                isError = true;
                            }
                            if (values.paymentType.getValue() === '') {
                                isError = true;
                            }
                            if (isError) {
                                reload(!reloadVar);
                                setBtnIsLoading(false);
                                return;
                            }
                            await sendRequest();
                            setBtnIsLoading(false);
                            navigate('/projectloans');
                        }}
                    >
                        Zeichnungsabsicht abschicken
                    </LoadingButton>
                </div>}
            </div>
        </Box>
    );

};
