import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AuthService from "../../services/AuthService";
import { Bank_Account } from "../../types";
import DataService from "../../services/DataService";
import { EditBankAccountTypesComp } from "./EditBankAccountTypesComp";
import EmptyTypesService from "../../services/EmptyTypesService";
import EnumToStringService from "../../services/EnumToStringService";
import { Loading } from "../../components/Loading";
import { LoadingButton } from "@mui/lab";

interface EditBankAccountDataProps {
    isMobile: boolean
    config: Config
}
export const EditBankAccount: React.FC<EditBankAccountDataProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{ editBankAccount: boolean }>({ editBankAccount: false });
    const { id } = useParams();
    //boolean if site is currently doing validations => shows "Speichern"-Button if it should show a loading circle
    const [saveBtnIsLoading, setSaveBtnIsLoading] = useState<boolean>(false)
    const [isError] = useState<boolean>(false)
    const [bankAccountData, setBankAccountData] = useState<Bank_Account[]>([EmptyTypesService.emptyBankAccount()]);

    const [bankAccountTypes, setBankAccountTypes] = useState<string[]>([]);

    const [bankAccountTypeToAdd, setBankAccountTypeToAdd] = useState<string>("");


    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performAsyncRequest(`/bankaccount/${id}`, finishedLoading, () => { }, setBankAccountData, "editBankAccount", navigate)
                //data has to be of Bank_Account type as it's bankAccount request. Can't work with bankAccountData object, as at this point it is still undefined, because site was not reloaded with new attributes, so we have to work with raw data directly (but it is the same as bankAccountData)

                .then((response) => {
                    //@ts-ignore
                    const rawObject: Bank_Account = response.data[0];
                    //contactData into values array
                    setBankAccountTypes(rawObject["bankAccountTypes"]);
                    setFinishedLoading({ editBankAccount: true });
                })
        }

    }, []);

    //Save request
    const saveBankAccount = async (setSaveBtnIsLoading: Function) => {
        DataService.performAsyncPutRequest(`/bankaccount/bankaccounttype`, navigate,
            {
                bankAcountId: bankAccountData[0].id,
                bankAccountTypes: bankAccountTypes.filter((s) => !EnumToStringService.getBankAccountType(s).startsWith("Darlehen ")),
            }
        ).then(() => {
            setSaveBtnIsLoading(false);
            navigate('/personaldata')
        })
    }

    const style = {
        marginTop: "10px",
    }

    if (!finishedLoading.editBankAccount) {
        return <Loading />
    }
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            p={3}
        >
            <Box
                width={props.isMobile ? '100%' : '1024px'}
            >
                <Typography variant="h6">Vewendungen des Kontos verändern</Typography>
                <TextField style={style} fullWidth value={bankAccountData[0].name} disabled name='name' label="Kontoinhaber" variant="outlined" size="small" />
                <TextField style={style} fullWidth value={bankAccountData[0].iban} disabled name='iban' label="IBAN" variant="outlined" size="small" />
                <EditBankAccountTypesComp isMobile={props.isMobile} config={props.config}
                    setBankAccountTypeToAdd={setBankAccountTypeToAdd}
                    setBankAccountTypes={setBankAccountTypes}
                    bankAccountTypeToAdd={bankAccountTypeToAdd}
                    bankAccountTypes={bankAccountTypes} />

                <div style={{ textAlign: "right" }}>
                    {isError && <Typography variant="caption" color={"#d32f2f"}>Bitte überprüfen Sie ihre Eingabe und speichern erneut</Typography>}
                </div>
                <div style={{ textAlign: "right" }}>
                    <LoadingButton loading={saveBtnIsLoading} variant="contained" disableElevation sx={{ marginTop: "10px" }} onClick={async () => {
                        setSaveBtnIsLoading(true);
                        saveBankAccount(setSaveBtnIsLoading);
                    }}>Speichern</LoadingButton>

                </div>
            </Box>
        </Box>
    )
}
