import { Box, FormHelperText, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AuthService from "../../services/AuthService";
import DataService from "../../services/DataService";
import EmptyTypesService from "../../services/EmptyTypesService";
import InputValue from "../../classes/InputValue";
import { Loading } from "../../components/Loading";
import { LoadingButton } from "@mui/lab";
import { Loan } from '../../types';
import { validationService } from "../../validation/ValidationService";

interface EditLoanApplicationDataProps {
    isMobile: boolean
    config: Config
}

export const EditLoanApplication: React.FC<EditLoanApplicationDataProps> = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [saveBtnIsLoading, setSaveBtnIsLoading] = useState<boolean>(false)
    const [finishedLoading, setFinishedLoading] = useState<{ editLoan: boolean }>({ editLoan: false });
    const [isError] = useState<boolean>(false)
    const [loanData, setLoanData] = useState<Loan[]>([EmptyTypesService.emptyLoan()]);
    const [values] = useState<any>({
        loanSum: new InputValue(props.config.loanMinSum, false),
    });
    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false)


    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performAsyncRequest(`/loan/${id}`, finishedLoading, () => { }, setLoanData, "editLoan", navigate)
        }

    }, []);

    useEffect(() => {
        // Set the initial loan sum when loanData is loaded
        if (finishedLoading.editLoan && loanData[0]) {
            values.loanSum.setValue(loanData[0].desiredValue || props.config.loanMinSum);
            reload(!reloadVar); // Trigger a re-render
        }
    }, [loanData]);

    //Save request
    const saveDesiredValue = async (setSaveBtnIsLoading: Function) => {
        if (values.loanSum.getError()) {
            setSaveBtnIsLoading(false);
            return;
        }
        DataService.performAsyncPutRequest(`/loan`, navigate,
            {
                id: loanData[0].id,
                desiredValue: values.loanSum.getValue(),
            }
        ).then(() => {
            setSaveBtnIsLoading(false);
            navigate('/projectloans')
        })
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueBefore = values["loanSum"].getValue();
        values["loanSum"].setValue(e.target.value)
        const newValue = parseInt(e.target.value);
        if (!validationService.validateFunction(values, "loanSum", "positiveFloat", false, false, props.config)) {
            values["loanSum"].setValue(valueBefore);
        }
        if (newValue % 1000 !== 0) {
            values["loanSum"].setError("Darlehensbetrag muss in 1000er Schritten angegeben werden!");
        }

        reload(!reloadVar);
    }

    const style = {
        marginTop: "10px",
    }

    if (!finishedLoading.editLoan) {
        return <Loading />
    }
    return (
        <Box
            className="content"
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            padding={2}
        >
            <Box
                className="tableDiv"
                width={props.isMobile ? "100%" : "50%"}
                display="inline-block"
            >
                <Typography variant="h6" gutterBottom>
                    Gewünschte Darlehenssumme ändern
                </Typography>

                {loanData[0].state !== "INTENT" && (
                    <Typography
                        variant="caption"
                        color="error"
                        gutterBottom
                    >
                        Achtung! Das Darlehen kann nicht mehr bearbeitet werden, da es bereits angenommen wurde oder eine Änderung durch die BEG angefragt wurde!
                    </Typography>
                )}

                <TextField
                    type="number"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    value={values.loanSum.getValue()}
                    onChange={handleChange}
                    error={values.loanSum.getError()}
                    name="loanSum"
                    label="Neuer Gewünschter Betrag des Darlehens"
                    variant="outlined"
                    size="small"
                    required
                    InputProps={{
                        inputProps: {
                            min: props.config.loanMinSum,
                            step: 1000,
                        },
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                />

                {values.loanSum.getError() && (
                    <FormHelperText error>
                        Der Darlehensbetrag darf nur in 1000er Schritten gewählt werden!
                    </FormHelperText>
                )}

                {isError && (
                    <Typography
                        variant="caption"
                        color="error"
                        sx={{ textAlign: "right", display: "block", marginTop: 1 }}
                    >
                        Bitte überprüfen Sie Ihre Eingabe und speichern erneut
                    </Typography>
                )}

                <Box textAlign="right" mt={2}>
                    <LoadingButton
                        loading={saveBtnIsLoading}
                        variant="contained"
                        disableElevation
                        onClick={async () => {
                            setSaveBtnIsLoading(true);
                            saveDesiredValue(setSaveBtnIsLoading);
                        }}
                    >
                        Speichern
                    </LoadingButton>
                </Box>
            </Box>
        </Box>

    )
}
